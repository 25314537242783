.cart_wrapper {
    width: 100vw;
    min-height: 100vh;
    background-color: #535353;
    display: flex;
    justify-content: center;
    display: flex;
    justify-content: center;
}

.cart {
    font-family: "Roboto", serif;
    padding: 50px 0 0 0;
    width: 830px;
    background-color: #000;
    position: relative;
    height: 750px;
    overflow-y: scroll;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    .container {
        width: 730px;
        margin: 0 auto;
        display: block;
    }

    .cart__cancel {
        position: absolute;
        right: 30px;
        top: 30px;
        cursor: pointer;
        &::after,
        &::before {
            content: "";
            width: 15px;
            height: 1px;
            background-color: #fff;
            display: inline-block;
            position: relative;
        }

        &::before {
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(135deg);
            left: -15px;
        }
    }

    .cart__title {
        font-size: 36px;
        font-weight: 600;
    }

    .cart__info {
        margin-top: 39px;
        width: 730px;
        padding-bottom: 20px;

        span {
            text-transform: uppercase;
            font-size: 12px;
        }

        span:first-child {
            padding-left: 125px;
        }

        span:nth-child(2) {
            padding-left: 155px;
        }
        span:nth-child(3) {
            padding-left: 95px;
        }

        span:nth-child(4) {
            padding-left: 120px;
        }
    }

    // .cart__item:first-child {
    //     border-top: 1px solid #1c1c1c;
    // }

    .cart__item {
        width: 100%;
        border-bottom: 1px solid #1c1c1c;
        border-top: 1px solid #1c1c1c;

        position: relative;
        height: 118px;
        display: flex;
        align-items: center;
        box-sizing: border-box;

        .item__image {
            position: absolute;
            left: 55px;
            top: 15px;
            display: inline-block;
            width: 80px;
            height: 80px;
        }

        .container {
            display: flex;
            justify-content: space-between;
            width: 545px;
        }

        .item__title {
            margin-left: 25px;
        }

        span {
            font-size: 12px;
            font-weight: 500;
            padding-left: 8px;
        }

        .count_wrapper {
            display: inline-flex;
            align-items: center;
            span {
                padding: 0 5px;
            }
            button {
                color: #fff;
            }
        }

        .item__cancel {
            top: 40px;
        }
    }

    .subtotal {
        display: flex;
        justify-content: space-between;
        padding: 30px 0;

        .subtotal__title {
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
        }
    }

    .cart__buttons {
        display: flex;
        justify-content: space-between;

        .cart__button {
            color: #fff;
            padding: 15px 0;
            border-radius: 20px;
            cursor: pointer;
            text-transform: uppercase;

            a {
                text-decoration: none;
                color: #fff;
            }
        }

        .back__btn {
            padding: 15px 30px;
            border: 1px solid #fff;
            svg {
                margin-right: 5px;
            }
        }

        .checkout_btn {
            border: 0;
            background-color: #23b6e4;
            padding: 15px 50px;
        }
    }

    .offers {
        margin-top: 50px;
        padding-bottom: 40px;

        .offers__title {
            margin: 0 auto;
            width: 300px;
            display: block;

            font-size: 24px;
            font-weight: 500;
        }

        .offers__items {
            display: flex;
            width: 620px;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin: 0 auto;
            margin-top: 45px;

            .offers__item {
                width: 148px;
                height: 217px;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 40px;
                margin-left: 48px;
                &_text {
                    margin-top: 20px;
                    text-align: center;
                }

                .item__img {
                    width: 148px;
                    height: 148px;
                }

                .item__price {
                    font-weight: 500;
                    margin-top: 15px !important;
                    display: block;
                    margin: 0 auto;
                }
            }
        }
    }
}
