@import "src/styles/utils";

.header {
  position: fixed;
  left: 50%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1240px;
  width: calc(100% - 122px);
  margin: 0 auto;
  padding: 0 10px;
  height: 66px;
  color: #fff;
  transition: all 100ms;
  z-index: 2;
  transform: translateX(-50%);
  animation-name: appear;
  animation-duration: 500ms;

  &.white {
    a {
      color: #000;
    }

    .link img {
      filter: brightness(1) invert(1);
    }
  }
}

.right {
  display: flex;
  align-items: center;
  column-gap: 95px;
}

.nav {
  display: flex;
  column-gap: 30px;

  a {
    color: #fff;
    font-size: 13px;
    text-transform: uppercase;
    text-decoration: none;
  }

  li {
    position: relative;
  }
}

.menu {
  display: flex;
  column-gap: 30px;

  a {
    color: #fff;
    font-size: 10px;
    text-transform: capitalize;
    text-decoration: none;
  }
}

.link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 7px;
}

.lambaCount {
  border-radius: 50%;
  position: absolute;
  top: -8px;
  right: -10px;
  width: 16px;
  height: 16px;
  background-color: #23b6e4;
  color: #000;
  font-size: 9px;
  text-align: center;
  font-weight: 900;
  padding-top: 4px;
}

@keyframes appear {
  0% {
    transform: translate(-50%, -100px);
  }

  100% {
    transform: translate(-50%, 0);
  }
}

@include mobile {
  .header {
    padding: 0 50px;
  }
}
